<template>
  <div class="productOverall">
    <!-- <div class="main-Title bgff"><h2>Offer Change log</h2></div> -->
    <div class="mainBox">
      <el-card shadow="hover" class="mb5 list-filter">
        <el-form :model="filter" label-width="70px" size="mini">
          <el-row>
            <el-col :xs="24" :sm="24" :md="6" :lg="6" :xl="6">
              <el-form-item label="manager" class="mb5">
                <el-select
                  v-model="filter.manager"
                  filterable
                  placeholder="Please select"
                  class="w100"
                >
                  <el-option
                    v-for="item in options.subRule"
                    :key="item.accountId"
                    :label="item.username"
                    :value="item.username"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="6" :lg="6" :xl="6">
              <el-form-item label="date" class="mb5" label-width="50px">
                <!-- :picker-options="pickerOptions" -->
                <el-date-picker
                  v-model="filter.date"
                  type="datetimerange"
                  range-separator="-"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  format="yyyy-MM-dd HH:mm:ss"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  :default-time="['00:00:00', '23:59:59']"
                  class="w100"
                  :clearable="false"
                ></el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="5" :lg="5" :xl="5">
              <el-form-item label="offerId" class="mb5" label-width="60px">
                <el-input v-model="filter.offerId"></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="5" :lg="5" :xl="5">
              <el-form-item label="ruleId" class="mb5" label-width="60px">
                <el-input v-model="filter.rejectRuleId"></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="2" :lg="2" :xl="2" align="right">
              <el-form-item class="mb5" label-width="0">
                <el-button type="primary" :loading="loading.list" @click="getList(1)"
                  >Search</el-button
                >
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </el-card>
      <el-card shadow="hover">
        <el-table
          :data="list"
          border
          stripe
          style="width:100%;"
          size="mini"
          v-loading="loading.list"
        >
          <el-table-column label="log_id" prop="id"></el-table-column>
          <el-table-column label="offer_id" prop="offerId"></el-table-column>
          <el-table-column label="offer_name" prop="offerName"></el-table-column>
          <el-table-column label="old_status" prop="oldStatus"></el-table-column>
          <el-table-column label="new_status" prop="newStatus"></el-table-column>
          <el-table-column label="managera" prop="manager"></el-table-column>
          <el-table-column label="log_time" prop="createTime"></el-table-column>
          <el-table-column label="action" prop="action"></el-table-column>
          <el-table-column label="rule_id" prop="rejectRuleId"></el-table-column>
          <el-table-column label="rule_name" prop="rejectRuleName"></el-table-column>
          <el-table-column label="detail" prop="description"></el-table-column>
        </el-table>
        <div class="align-c">
          <pagination
            class="block pagePanel"
            :pageTotal="total"
            @handleSizeChange="handleSizeChange"
            @handleCurrentChange="handleCurrentChange"
            :pageSize="pageSize"
            :currentPage="currentPage"
          ></pagination>
        </div>
      </el-card>
    </div>
  </div>
</template>
<script>
  import OfferChangeLogCon from '../controllers/product/OfferChangeLog';
  export default {
    name: 'OfferChangeLog',
    ...OfferChangeLogCon,
  };
</script>
<style lang="scss" scoped>
  .cor337ab7 {
    color: #337ab7;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
</style>
