import request from 'utils/request';

const api = {
  OFFER_CHANGE_LOG_PAGE: '/api/close/rule/selectLogs',
};

/**
 * 查询close/rule分页
 * @param {*} query {smartLinkIds:Array<int>,status:string,page:int,pageSize:int}
 */
export function fetchOfferChangeLogPage(query) {
  return request({
    url: api.OFFER_CHANGE_LOG_PAGE,
    method: 'get',
    params: query,
  });
}
