import { fetchOfferChangeLogPage } from 'api/product/offerChangeLog.js';
import { getPermissionAllList } from 'api/account/permission';
import Pagination from '@/components/pagination';
export default {
  components: {
    Pagination,
  },
  data() {
    return {
      filter: {
        date: [],
      },
      // pickerOptions: {
      //   disabledDate(time) {
      //     // return time.getTime() < Date.now(); // 选当前时间之后的时间
      //     return time.getTime() > Date.now(); // 选当前时间之前的时间
      //   },
      // },
      total: null,
      pageSize: 20,
      currentPage: 1,
      list: [],
      loading: {
        list: false,
      },
      options: {
        subRule: [],
      },
    };
  },
  mounted() {
    this.getManagers();
    this.getList();
  },
  methods: {
    // 获取Managers
    getManagers() {
      getPermissionAllList().then((res) => {
        console.log(res);
        this.options.subRule = res.result;
      });
    },
    // 获取列表
    getList(curPage) {
      if (curPage) {
        this.currentPage = curPage;
      }
      let param = {
        ...this.filter,
        page: this.currentPage,
        pageSize: this.pageSize,
      };
      if (this.filter.date?.length > 0) {
        param.fromDate = this.filter.date[0];
        param.toDate = this.filter.date[1];
        delete param.date;
      }
      this.loading.list = true;
      fetchOfferChangeLogPage(param)
        .then((res) => {
          if (res.code === 200) {
            this.list = res.result;
            this.total = res.total;
          } else {
            this.$message.error(res.message);
          }
          this.loading.list = false;
        })
        .catch((e) => {
          this.$message.error(e.message);
          this.loading.list = false;
        });
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.handleCurrentChange(1);
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getList();
    },
  },
};
